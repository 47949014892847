import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  aiFlowService,
  libraryService,
  promptService,
  userService,
} from '../api';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';

import { BotInput } from '../molecules';
import { AIFlowItem, BundleListType } from '../types';

export const NewConversation: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const { retrievalBundlesTagsList = [] } = libraryService();
  const { isDeveloper } = userService();
  const { aiFlowList } = aiFlowService();

  const { sendQuestion, questionIsPending } = promptService();
  const [showBundleSelection, setShowBundleSelection] = React.useState(false);
  const [question, setQuestion] = React.useState<string>();
  const [selectedBundles, setSelectedBundles] =
    React.useState<BundleListType[]>();
  const [selectedAIflowSlug, setSelectedAIflowSlug] = React.useState<
    string | null
  >(null);

  const handleSelectBundle = (event: any, bundles: BundleListType[]) => {
    setSelectedBundles(bundles);
  };

  const handleSelectAIflow = (event: any, flow: AIFlowItem | null) => {
    setSelectedAIflowSlug(flow?.slug ? flow.slug : null);
  };

  const selectedSlugs = selectedBundles?.map(({ slug }) => slug);

  const handleConfirm = () => {
    setShowBundleSelection(false);

    if (question && selectedSlugs) {
      sendQuestion({
        prompt: question,
        retrieval_bundle_slugs: selectedSlugs,
        aiflow_slug: selectedAIflowSlug,
      });
    }
  };

  const handleClose = () => {
    setShowBundleSelection(false);
  };

  return loggedIn ? (
    <>
      <BotInput
        loading={questionIsPending}
        chips={selectedBundles?.map(({ name }) => name)}
        onSubmit={(value) => {
          if (!selectedSlugs?.length) {
            setQuestion(value);
            setShowBundleSelection(true);
          } else {
            setQuestion(undefined);
            sendQuestion({
              prompt: value,
              retrieval_bundle_slugs: selectedSlugs,
            });
          }
        }}
      />

      <Dialog open={showBundleSelection}>
        <DialogTitle>Please select source docs</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              All answers in this Conversation will be based on the selected
              sources. Please note that at least one source has to be selected.
            </DialogContentText>

            <Autocomplete
              multiple
              id="bundle-tag-selector"
              options={retrievalBundlesTagsList}
              filterSelectedOptions
              onChange={handleSelectBundle}
              getOptionLabel={({ name, description }) => name}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={option.slug} {...optionProps}>
                    {option.name}
                    <br />({option.description})
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source docs"
                  placeholder="Select source docs"
                />
              )}
            />

            {isDeveloper && aiFlowList && (
              <Autocomplete
                id="aiflow-tag-selector"
                options={aiFlowList}
                filterSelectedOptions
                onChange={handleSelectAIflow}
                getOptionLabel={({ name }) => name}
                getOptionKey={({ slug }) => slug}
                defaultValue={aiFlowList.find(
                  ({ default: selected }) => selected,
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="AI flow"
                    placeholder="Select AI flow"
                  />
                )}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            disabled={!selectedBundles?.length}
            variant="contained"
            onClick={handleConfirm}
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};
