import * as React from 'react';
import { UserContext } from '../context/userContext';

import Box from '@mui/material/Box';
import { Card, CardContent, CardHeader, Toolbar } from '@mui/material';
import { Helmet } from 'react-helmet';
import { AreasSetting } from '../molecules';

export const SettingsPage: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);

  return loggedIn ? (
    <Box component="main" p={2}>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Card sx={{ minWidth: 400 }}>
        <CardHeader
          title="Areas"
          subheader="Please select at least one area."
        />
        <CardContent>
          <AreasSetting />
        </CardContent>
        {/* <CardActions>
          <Button size="small">Save</Button>
        </CardActions> */}
      </Card>
    </Box>
  ) : null;
};
