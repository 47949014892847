import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { conversationService, libraryService } from '../api';
import { NavLink, useNavigate } from 'react-router-dom';
import { ConversationItem } from '../types';
import { Helmet } from 'react-helmet';
import { NewConversation } from '.';
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { format, formatDistance, parseISO } from 'date-fns';
import { gatBundleNameList } from '../tools';

const ActionItem = ({
  confirmationLabel,
  params,
  onAction,
  ...props
}: GridActionsCellItemProps & {
  onAction?: (params: any) => void;
  confirmationLabel?: string;
  params: GridRowParams; // TODO: replace any with typescript type
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (confirmationLabel) {
      setOpen(true);
    } else {
      if (onAction) {
        onAction(params);
      }
    }
  };

  return (
    <React.Fragment>
      <GridActionsCellItem {...props} onClick={handleClick} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmationLabel}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false);
              if (onAction) {
                onAction(params);
              }
            }}
            color="warning"
            autoFocus
          >
            {confirmationLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

interface Props {
  conversationList?: ConversationItem[];
  menuTitle: string;
  isLoading: boolean;
}

export const ConversationsPage: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const { conversationList, sharedConversationList } = conversationService();
  const { retrievalBundlesTagsList } = libraryService();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      renderCell: (params) => (
        <Button
          component={NavLink}
          to={`/conversation/${params.row.id}`}
          sx={{
            color: 'black',
            fontWeight: 'normal',
            textTransform: 'none',
            justifyContent: 'left',
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'sources',
      headerName: 'Sources',
      width: 220,
      renderCell: (params) =>
        gatBundleNameList(retrievalBundlesTagsList, params.value).join(', '),
    },
    {
      field: 'isShared',
      headerName: 'Shared',
      width: 80,
      align: 'center',
      display: 'flex',
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <Stack>{params.value && <ShareIcon />}</Stack>
      ),
    },
    {
      field: 'asked',
      headerName: 'Asked',
      width: 140,
      type: 'string',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>{format(parseISO(params.value + 'Z'), 'dd.MM.yyyy HH:mm')}</>
      ),
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 140,
      type: 'string',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>
          {formatDistance(parseISO(params.value + 'Z'), new Date(), {
            addSuffix: true,
          })}
        </>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      getActions: (params) => [
        // <ActionItem
        //   params={params}
        //   label="Delete"
        //   showInMenu
        //   icon={<DeleteIcon />}
        //   onAction={() => {}}
        //   confirmationLabel="Delete"
        //   closeMenuOnClick={false}
        // />,
        <ActionItem
          showInMenu
          params={params}
          label="Open"
          icon={<OpenInNewOutlinedIcon />}
          onAction={(params) => {
            navigate(`/conversation/${params.id}`);
          }}
          closeMenuOnClick={false}
        />,
      ],
    },
  ];

  const paginationModel = { page: 0, pageSize: 10 };

  const rows = [
    ...(conversationList?.map(
      ({ uid, name, created_at, updated_at, retrieval_bundle_slugs }) => ({
        id: uid,
        title: name,
        isShared: false,
        asked: created_at,
        updated: updated_at,
        sources: retrieval_bundle_slugs,
      }),
    ) || []),
    ...(sharedConversationList?.map(
      ({ uid, name, created_at, updated_at, retrieval_bundle_slugs }) => ({
        id: uid,
        title: name,
        isShared: true,
        asked: created_at,
        updated: updated_at,
        sources: retrieval_bundle_slugs,
      }),
    ) || []),
  ];

  return loggedIn ? (
    <>
      <Helmet>
        <title>Conversations</title>
      </Helmet>
      <AppBar position="sticky">
        <Toolbar
          sx={{
            background: '#dfdfdf',
          }}
        >
          <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              sx={{
                color: 'black',
                lineHeight: 1,
              }}
            >
              Conversations
            </Typography>
            {/* {!canManage ? (
                    <IconButton
                      sx={{
                        color: 'black',
                        margin: 0,
                      }}
                      onClick={handleManageDashboard}
                      size="small"
                    >
                      <SettingsIcon />
                    </IconButton>
                  ) : (
                    <Button
                      onClick={handleManageDashboard}
                      variant="text"
                      color="primary"
                    >
                      Save layout changes
                    </Button>
                  )} */}
          </Stack>
        </Toolbar>
        {/* <Toolbar />
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      // onClick={handleDrawerToggle}KeyboardDoubleArrowUpIcon
                      sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                      <KeyboardDoubleArrowUpIcon />
                    </IconButton>
                  </Toolbar> */}
      </AppBar>
      <Box component="main" p={2}>
        <Stack spacing={2}>
          <Card sx={{ minWidth: 600 }}>
            <CardContent>
              <DataGrid
                density="compact"
                rows={rows}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                // pageSizeOptions={[10, 20]}
                // checkboxSelection
                disableRowSelectionOnClick
                sx={{ border: 0 }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </CardContent>
          </Card>

          <NewConversation />
        </Stack>
      </Box>
    </>
  ) : null;
};
