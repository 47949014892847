import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';

import { conversationService, userService } from '../api';
import { ListSkeleton } from './Skeletons';
import { NavLink } from 'react-router-dom';
import { ConversationItem } from '../types';
import { BundleTextList, AiFlowTextList } from '../atoms';
import { ReactComponent as NoConversationsIcon } from '../icons/noConversations.svg';
import { ReactComponent as NoSharedConversationsIcon } from '../icons/noSharedConversations.svg';

interface Props {
  conversationList?: ConversationItem[];
  isLoading: boolean;
}

const ListMenu: React.FC<Props> = ({ conversationList, isLoading }) => {
  const { loggedIn } = React.useContext(UserContext);
  const { isDeveloper } = userService();

  return loggedIn ? (
    <>
      {isLoading && <ListSkeleton contrast />}
      <List dense disablePadding>
        {conversationList?.map(
          ({ name, uid, retrieval_bundle_slugs = [''], aiflow_slug }) => (
            <ListItem
              component={NavLink}
              sx={{ color: 'black' }}
              to={`/conversation/${uid}`}
              key={uid}
              disablePadding
              dense
            >
              <ListItemButton sx={{ pl: 3 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <ArtTrackIcon />
                </ListItemIcon>
                <Tooltip
                  title={
                    <>
                      <BundleTextList slugs={retrieval_bundle_slugs} />

                      {isDeveloper && (
                        <>
                          <br />
                          <AiFlowTextList slug={aiflow_slug} />
                        </>
                      )}
                    </>
                  }
                >
                  <ListItemText
                    sx={{
                      minWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    primary={name}
                  />
                </Tooltip>
              </ListItemButton>
            </ListItem>
          ),
        )}
      </List>
    </>
  ) : null;
};

export const ConversationList: React.FC = () => {
  const { conversationList, conversationIsLoading } = conversationService();

  const list = conversationList?.slice(0, 5);

  return list?.length ? (
    <ListMenu conversationList={list} isLoading={conversationIsLoading} />
  ) : (
    <>
      <Box color="#808080" pl={3}>
        There are no conversations,{' '}
        <Button size="small" component={NavLink} to={'/explore'} variant="text">
          start exploring
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={200}
        sx={{ opacity: 0.5 }}
      >
        <NoConversationsIcon height="150px" />
      </Box>
    </>
  );
};

export const SharedConversationList: React.FC = () => {
  const { sharedConversationList, conversationIsLoading } =
    conversationService();

  const list = sharedConversationList?.slice(0, 10);

  return list?.length ? (
    <ListMenu conversationList={list} isLoading={conversationIsLoading} />
  ) : (
    <>
      <Box color="#808080" pl={3}>
        You have no shared conversations.
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={200}
        sx={{ opacity: 0.5 }}
      >
        <NoSharedConversationsIcon height="150px" />
      </Box>
    </>
  );
};
