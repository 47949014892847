import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import theme from '../theme';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../icons/logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import TaskIcon from '@mui/icons-material/Task';
import PatternIcon from '@mui/icons-material/Pattern';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import {
  KnowledgeMenu,
  Login,
  Register,
  Reset,
  ShowSystemNotification,
  SourcesMenu,
} from '.';
import { UserContext } from '../context/userContext';

export const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const { loggedIn } = React.useContext(UserContext);

  const [showSideBar, setShowSideBar] = React.useState(true);
  const registerButtonRef = React.useRef<HTMLButtonElement>(null);
  const resetButtonRef = React.useRef<HTMLButtonElement>(null);
  const drawerWidth = loggedIn && showSideBar ? 280 : 0;

  const toggleSidebar = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowSideBar(!showSideBar);
  };

  const handleNewConversation = () => {
    navigate('/');
  };
  return (
    <>
      <AppBar
        color="blue"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div onClick={handleNewConversation}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleSidebar}
              >
                <MenuIcon />
              </IconButton>
              <Logo cursor="pointer" height={28} width={40} fill="#fff" />
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                noWrap
                variant="h6"
              >
                Industrial Knowledge Manager
              </Typography>
              <Typography
                sx={{
                  opacity: 0.5,
                  paddingLeft: 1,
                }}
                variant="subtitle1"
              >
                v{process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </div>
          <Stack alignItems="center" spacing={2} direction="row">
            <ShowSystemNotification />
            <Login
              onRegisterClick={() => {
                registerButtonRef.current?.click();
              }}
              onResetClick={() => {
                resetButtonRef.current?.click();
              }}
            />
            <Register buttonRef={registerButtonRef} />
            <Reset hideButton buttonRef={resetButtonRef} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#013366',
            color: 'white',
          },
          '& .MuiSvgIcon-root': {
            fill: 'white',
          },

          '& .MuiListItem-root': {
            color: 'white',
          },
          '& .MuiListItem-root.active': {
            color: '#013366',
            '& .MuiSvgIcon-root': {
              fill: '#013366',
            },
            bgcolor: '#dfdfdf',
          },
        }}
        variant="persistent"
        anchor="left"
        open={loggedIn && showSideBar}
      >
        <Toolbar />

        <List dense sx={{ paddingTop: 0 }}>
          <ListItem component={NavLink} to={'/'}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <KnowledgeMenu />

          <ListItem dense sx={{ opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <GroupWorkIcon />
            </ListItemIcon>
            <ListItemText primary="Workspaces" />
          </ListItem>

          <ListItem dense sx={{ opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <PatternIcon />
            </ListItemIcon>
            <ListItemText primary="Workflows" />
          </ListItem>

          <ListItem dense component={NavLink} to={'/review-requests'}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <PatternIcon />
            </ListItemIcon>
            <ListItemText primary="Review Requests" />
          </ListItem>

          <SourcesMenu />

          <ListItem sx={{ opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <WebStoriesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Templates" />
          </ListItem>

          <ListItem sx={{ opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <TaskIcon />
            </ListItemIcon>
            <ListItemText primary="Jobs" />
          </ListItem>

          <ListItem sx={{ opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <InterpreterModeIcon />
            </ListItemIcon>
            <ListItemText primary="Interviews" />
          </ListItem>

          <ListItem component={NavLink} to={'/settings'}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
