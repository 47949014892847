import * as React from 'react';
import { UserContext } from '../context/userContext';
import { closestCenter, DndContext, useDroppable } from '@dnd-kit/core';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  ConversationList,
  ReviewsMenu,
  SharedConversationList,
  SystemNotificationList,
} from '../molecules';
import useLocalStorageState from 'use-local-storage-state';
import { userService } from '../api';
import ShareIcon from '@mui/icons-material/Share';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { NewConversation } from './NewConversation';

type DroppedItem = {
  id: string;
  area: string;
};

type WidgetItem = {
  id: string;
  label: string;
  icon: React.ReactNode;
};

const widgets: WidgetItem[] = [
  {
    id: 'widget-1',
    label: 'Notifications',
    icon: <NotificationsOutlinedIcon />,
  },
  { id: 'widget-2', label: 'My Conversations', icon: <ChatOutlinedIcon /> },
  { id: 'widget-3', label: 'Shared Conversations', icon: <ShareIcon /> },
  {
    id: 'widget-4',
    label: 'Review requests',
    icon: <RateReviewOutlinedIcon />,
  },
];

const Widget = ({
  id,
  children,
  canManage,
  icon,
  onRemove,
}: {
  id: string;
  children: any;
  canManage: boolean;
  icon: React.ReactNode;
  onRemove: (id: string) => void;
}) => {
  const handleRemove = () => {
    onRemove(id);
  };

  return (
    <Paper
      sx={{
        flex: 1,
        overflow: 'inherit',
      }}
    >
      <Stack alignItems="center" p={2} direction="row" gap={1}>
        {icon}
        <Typography variant="h6" component="h2">
          {children}
        </Typography>
      </Stack>
      <Box pl={3} pb={3} pr={3}>
        {/* TODO: replace & rename all lists with more styled ones */}
        {id === 'widget-1' && <SystemNotificationList />}
        {id === 'widget-2' && <ConversationList />}
        {id === 'widget-3' && <SharedConversationList />}
        {id === 'widget-4' && <ReviewsMenu />}
      </Box>
      {canManage && (
        <Box textAlign="right" p={3}>
          <Button size="small" onClick={handleRemove}>
            Remove widget
          </Button>
        </Box>
      )}
    </Paper>
  );
};

const DroppableArea = ({
  areaId,
  droppedItems,
  widgets,
  canManage,
  flex = 1,
  children,

  setDroppedItems,
}: {
  areaId: string;
  droppedItems: DroppedItem[];
  widgets: WidgetItem[];
  canManage: boolean;
  flex?: number;
  children?: any;
  setDroppedItems: React.Dispatch<React.SetStateAction<DroppedItem[]>>;
}) => {
  const { setNodeRef } = useDroppable({
    id: areaId,
  });

  const handleRemove = (id: string) => {
    const newItems = droppedItems.filter(
      (droppedItem) => droppedItem.id !== id,
    );
    setDroppedItems(newItems);
  };

  const editStyle = canManage
    ? {
        padding: '8px',
        border: '2px dashed #ccc',
        minHeight: '600px',
      }
    : {};

  return (
    <Stack
      spacing={2}
      ref={setNodeRef}
      style={{
        flex,
        justifyContent: 'center',
        display: 'flex',
        ...editStyle,
      }}
    >
      {droppedItems
        .filter(({ area }) => area === areaId)
        .map(({ id }) => {
          const widget = widgets.find((btn) => btn.id === id);
          if (widget) {
            return (
              <Widget
                id={id}
                icon={widget.icon}
                canManage={canManage}
                onRemove={handleRemove}
              >
                {widget.label}
              </Widget>
            );
          }
        })}
      {children}
    </Stack>
  );
};

const DraggableWidget = ({ id, children }: { id: string; children: any }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      variant="contained"
      color="primary"
    >
      {children}
    </Button>
  );
};

export const DashboardPage: React.FC = () => {
  const { userInfo } = userService();
  const { loggedIn } = React.useContext(UserContext);
  const [canManage, setCanManage] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [draggingId, setDraggingId] = React.useState('');
  // const [droppedItems, setDroppedItems] = React.useState<DroppedItem[]>([]);
  const [droppedItems, setDroppedItems] = useLocalStorageState<DroppedItem[]>(
    `dashboard_${userInfo?.kc_uid}`,
    {
      defaultValue: [
        { id: 'widget-1', area: 'droppable-area-2' },
        { id: 'widget-2', area: 'droppable-area-1' },
        { id: 'widget-3', area: 'droppable-area-1' },
        { id: 'widget-4', area: 'droppable-area-2' },
      ],
    },
  );
  const userName = userInfo?.first_name || userInfo?.last_name;

  const handleDragStart = (event: any) => {
    setIsDragging(true);
    setDraggingId(event.active.id); // Track which button is being dragged
  };

  const handleDragEnd = (event: any) => {
    setIsDragging(false);
    const {
      over: { id: area },
    } = event;

    if (area) {
      // Add the dragged button to the droppedItems list if it's not already there
      if (!droppedItems.find(({ id }) => id === draggingId)) {
        setDroppedItems((state) => [...state, { id: draggingId, area }]);
      }
    }
  };

  const handleManageDashboard = () => {
    setCanManage((state) => !state);
  };

  return loggedIn ? (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <AppBar position="sticky">
        <Toolbar
          sx={{
            background: '#dfdfdf',
          }}
        >
          <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              sx={{
                color: 'black',
                lineHeight: 1,
              }}
            >
              {`Welcome, ${userName}!`}
            </Typography>
            {!canManage ? (
              <IconButton
                sx={{
                  color: 'black',
                  margin: 0,
                }}
                onClick={handleManageDashboard}
                size="small"
              >
                <SettingsIcon />
              </IconButton>
            ) : (
              <Button
                onClick={handleManageDashboard}
                variant="text"
                color="primary"
              >
                Save layout changes
              </Button>
            )}
          </Stack>
        </Toolbar>
        {/* <Toolbar />
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                // onClick={handleDrawerToggle}KeyboardDoubleArrowUpIcon
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <KeyboardDoubleArrowUpIcon />
              </IconButton>
            </Toolbar> */}
      </AppBar>

      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Stack p={2}>
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <Stack direction="row" spacing={1}>
            <Stack direction="row" flex={7} spacing={2}>
              <DroppableArea
                areaId="droppable-area-1"
                flex={2}
                widgets={widgets}
                canManage={canManage}
                droppedItems={droppedItems}
                setDroppedItems={setDroppedItems}
              />

              <DroppableArea
                areaId="droppable-area-2"
                widgets={widgets}
                canManage={canManage}
                droppedItems={droppedItems}
                setDroppedItems={setDroppedItems}
              />
            </Stack>
            {canManage && (
              <Stack flex={1} spacing={1}>
                {widgets
                  .filter(
                    (widget) =>
                      !droppedItems.find(({ id }) => widget.id === id),
                  )
                  .map((widget) => (
                    <DraggableWidget key={widget.id} id={widget.id}>
                      {widget.label}
                    </DraggableWidget>
                  ))}
              </Stack>
            )}
          </Stack>
          {/* <DragOverlay>
          {isDragging ? (
            <Button variant="contained" color="primary">
              Dragging...
            </Button>
          ) : null}
        </DragOverlay> */}
        </Stack>
      </DndContext>
      <Box pl={2} pr={2}>
        <NewConversation />
      </Box>
    </>
  ) : null;
};
