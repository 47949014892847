import * as React from 'react';
import { IconButton } from '@mui/material';
import { Confirm } from '../molecules';
import { useModal } from '../hooks';

interface Props {
  actionLabel: string;
  confirmTitle?: string;
  confirmText?: string;
  icon: React.ReactNode;
  onAction: () => void;
}

export const Action: React.FC<Props> = ({
  actionLabel,
  confirmTitle,
  confirmText,
  icon,
  onAction,
}) => {
  const showDialog = !!confirmTitle && !!confirmText;
  const { openModal, closeModal, currentModal } = useModal();

  const handleAction = () => {
    if (showDialog) {
      openModal(actionLabel);
    } else {
      executeAction();
    }
  };

  const executeAction = () => {
    onAction();
  };

  return (
    <>
      <IconButton
        sx={{
          color: 'black',
          margin: 0,
        }}
        onClick={handleAction}
        size="small"
      >
        {icon}
      </IconButton>

      {showDialog && (
        <Confirm
          show={currentModal === actionLabel}
          title={confirmTitle}
          question={confirmText}
          confirmLabel={actionLabel}
          onClick={(confirm) => {
            if (confirm) {
              executeAction();
            }
            closeModal(actionLabel);
          }}
        />
      )}
    </>
  );
};
