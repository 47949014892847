import { useState } from 'react';

export const useModal = <T extends string>() => {
  const [currentModal, setCurrentModal] = useState<T | null>(null);

  const openModal = (modalName: T) => setCurrentModal(modalName);
  // embed logic for multiple dialog open in paralel
  const closeModal = (modalName: T) => setCurrentModal(null);

  const toggleModal = (modalName: T) => {
    if (currentModal === modalName) {
      closeModal(modalName);
    } else {
      openModal(modalName);
    }
  };

  return { openModal, closeModal, toggleModal, currentModal };
};
