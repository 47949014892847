import * as React from 'react';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import libOwnerSource from '../mocks/libOwner.json';
import { Link } from 'react-router-dom';
import { ReactComponent as NoLibraryIcon } from '../icons/noLibrary.svg';

interface Props extends React.PropsWithChildren {
  slug?: string;
}

const NoLib = ({ hasSlug }: { hasSlug: boolean }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      height={200}
      sx={{ opacity: 0.5 }}
    >
      <NoLibraryIcon height="150px" />
      {hasSlug ? 'No additional info available.' : 'Please select a library.'}
    </Box>
  );
};

export const LibraryProperties: React.FC<Props> = ({ slug }) => {
  const libItem = libOwnerSource.find(
    ({ library: { lib_line, lib_version } }) =>
      slug?.indexOf(`${lib_line}_${lib_version}`) !== -1,
  );
  if (!libItem) {
    return <NoLib hasSlug={!!slug} />;
  }

  return (
    <Stack spacing={2}>
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        target="_blank"
        to={libItem.url}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <img height="50px" src={libItem.logo_url}></img>
          <Stack>
            <Typography variant="h6">{libItem.short_name}</Typography>
            {/* <Typography variant="h6">{libItem.long_name}</Typography> */}
            {libItem.long_name}
          </Stack>
        </Stack>
      </Link>
      <Typography variant="body1">{libItem.description}</Typography>
      <b>Description</b>
      <Typography variant="body2">{libItem.lib_long_descrpition}</Typography>
      <b>Who should use it</b>
      <Typography variant="body2">{libItem.who_should_use_lib}</Typography>
      <b>Statistics</b>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow key="images">
              <TableCell component="th" scope="row">
                Images
              </TableCell>
              <TableCell align="right">{libItem.stats.images_count}</TableCell>
            </TableRow>
            <TableRow key="all-docs">
              <TableCell component="th" scope="row">
                All documents
              </TableCell>
              <TableCell align="right">
                {libItem.stats.document_total_count}
              </TableCell>
            </TableRow>
            {libItem.stats.doc_groups.map((row) => (
              <TableRow key={row.name}>
                <TableCell sx={{ paddingLeft: 4 }} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
