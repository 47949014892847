import * as React from 'react';
import { LibraryProperties, ReportMissingDocument } from '../molecules';
import { UserContext } from '../context/userContext';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { libraryService } from '../api';
import { LibraryListType } from '../types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const columns: GridColDef[] = [
  {
    field: 'uc_doc_title_per_owner',
    headerName: 'Title',
    flex: 1,
  },
];

export const LibraryPage: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const [selectedSlug, setSelectedSlug] = React.useState('');
  const { libraryTagList = [], getLibrary } = libraryService();
  const paginationModel = { page: 0, pageSize: 20 };

  const rows = getLibrary(selectedSlug);

  const handleSelectBundle = (event: any, bundle: LibraryListType | null) => {
    if (bundle?.slug) {
      setSelectedSlug(bundle?.slug);
    }
  };

  return loggedIn ? (
    <Box component="main" p={2}>
      <Helmet>
        <title>Library</title>
      </Helmet>
      <Stack spacing={2} direction="row">
        <Card sx={{ flex: 2, minWidth: 400 }}>
          <CardHeader title="Library" />

          <CardContent>
            <Stack spacing={2}>
              <Autocomplete
                id="bundle-tag-selector"
                sx={{ width: 300 }}
                autoHighlight
                options={libraryTagList}
                filterSelectedOptions
                onChange={handleSelectBundle}
                getOptionLabel={({ name }) => name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Library"
                    placeholder="Please select library."
                  />
                )}
              />
              {/* ISSUE witdth width https://github.com/mui/mui-x/issues/11295 */}
              {!!selectedSlug && (
                <DataGrid
                  rows={rows}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  columns={columns}
                  initialState={{ pagination: { paginationModel } }}
                />
              )}
              <Box textAlign="right">
                <ReportMissingDocument label="Document" />
              </Box>
            </Stack>
          </CardContent>
        </Card>
        <Card sx={{ flex: 1, minWidth: 400 }}>
          <Stack>
            <CardHeader
              title={
                <Stack alignItems="center" direction="row" gap={1}>
                  <InfoOutlinedIcon />
                  Library properties
                </Stack>
              }
            />

            <CardContent>
              <LibraryProperties slug={selectedSlug} />
            </CardContent>
          </Stack>
        </Card>
      </Stack>
    </Box>
  ) : null;
};
