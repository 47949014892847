import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

export const SourcesMenu: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return loggedIn ? (
    <>
      <ListItem onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <ArticleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Sources" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense disablePadding>
          <ListItem sx={{ pl: 3 }} component={NavLink} to={'/library'}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary="Library" />
          </ListItem>

          <ListItem sx={{ opacity: 0.5, pl: 3 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <IntegrationInstructionsIcon />
            </ListItemIcon>
            <ListItemText primary="Doc providers" />
          </ListItem>
        </List>
      </Collapse>
    </>
  ) : null;
};
