import * as React from 'react';
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Paper,
  Stack,
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import zIndex from '@mui/material/styles/zIndex';

interface Props {
  loading: boolean;
  value?: string;
  label?: string;
  chips?: string[];
  onSubmit: (value: string) => void;
}

export const BotInput: React.FC<Props> = ({
  loading,
  value = '',
  label = 'New Question',
  chips,
  onSubmit,
}) => {
  const [question, setQuestion] = React.useState(value);
  const canSubmit = !!question;

  React.useEffect(() => {
    setQuestion(value);
  }, [value]);

  const handleSubmitQuestion = (value: string) => () => {
    if (canSubmit) {
      onSubmit(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (canSubmit && event.key === 'Enter') {
      onSubmit(question);
    }
  };

  return (
    <Box position="sticky" bottom={8} zIndex={zIndex.drawer + 1}>
      <Paper elevation={12} sx={{ mt: 'auto', width: '100%' }}>
        <Stack spacing={1} padding={1}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="question">{label}</InputLabel>
            <OutlinedInput
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={handleKeyDown}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={!canSubmit}
                    onClick={handleSubmitQuestion(question)}
                    edge="end"
                  >
                    <SendIcon color={canSubmit ? 'primary' : 'disabled'} />
                  </IconButton>
                </InputAdornment>
              }
              label={label}
            />
            {loading && <LinearProgress sx={{ margin: '2px' }} />}
          </FormControl>
          <Stack direction="row" spacing={1}>
            {chips &&
              chips.map((chipLabel) => (
                <Chip
                  key={chipLabel}
                  label={chipLabel}
                  size="small"
                  variant="outlined"
                />
              ))}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};
