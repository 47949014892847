import * as React from 'react';
import {
  Alert,
  Autocomplete,
  Badge,
  badgeClasses,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import { conversationService, userService } from '../api';
import { useEffect } from 'react';
import { UserContext } from '../context/userContext';
import ShareIcon from '@mui/icons-material/Share';
import ClearIcon from '@mui/icons-material/Clear';
import { SharedUserItem } from '../types';
import isEqual from 'react-fast-compare';

const ShareBadge = styled(Badge)`
  & .${badgeClasses.badge} {
    top: -8px;
    font-size: 10px;
    height: 14px;
    min-width: 14px;
  }
`;

interface Props {
  conversationId: string;
  onShare?: () => void;
}

export const ShareConversation: React.FC<Props> = ({
  conversationId,
  onShare,
}) => {
  const { usersList, userInfo } = userService();
  const usersListOptions = usersList?.filter(
    ({ kc_uid }) => kc_uid !== userInfo?.kc_uid,
  );

  const {
    conversationShare,
    conversationShareIsPending,
    getConversationShare,
  } = conversationService();
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<SharedUserItem[]>(
    [],
  );

  const { users: sharedConversationUsers } =
    getConversationShare(conversationId);

  const shouldSave = !isEqual(sharedConversationUsers, selectedUsers);
  const isInitialShare = !sharedConversationUsers?.length;

  // BUGFIX: users in the list get's preloaded, but when there are users on conv, then they are not removed from the list and can be selected twice

  const handleOpen = () => setShowShareDialog(true);

  const handleClose = () => {
    setShowShareDialog(false);
  };

  const handleShareChange = (e: any, selectedOptions: SharedUserItem[]) => {
    setSelectedUsers(selectedOptions);
  };

  const { loggedIn } = React.useContext(UserContext);

  useEffect(() => {
    if (loggedIn) {
      handleClose();
    } else {
      setShowShareDialog(true);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (sharedConversationUsers?.length) {
      setSelectedUsers(sharedConversationUsers);
    }
  }, [sharedConversationUsers]);

  const handleSave = () => {
    updateShare();
  };

  const updateShare = (clearAll?: boolean) => {
    const users = clearAll
      ? []
      : (selectedUsers.map(({ kc_uid }) => kc_uid) as string[]);
    conversationShare({ uid: conversationId, users }).then(() => {
      handleClose();
      if (onShare) {
        onShare();
      }
    });
  };

  return (
    <>
      {loggedIn && (
        <IconButton
          sx={{
            color: 'black',
            margin: 0,
          }}
          onClick={handleOpen}
          size="small"
        >
          <ShareBadge
            badgeContent={sharedConversationUsers?.length}
            color="primary"
            variant="standard"
            overlap="circular"
          >
            <ShareIcon />
          </ShareBadge>
        </IconButton>
      )}
      <Dialog onClose={handleClose} open={showShareDialog}>
        <DialogTitle>Share conversation</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              Select one or more users to share the conversation. <br />
            </DialogContentText>
            <Autocomplete
              clearIcon={<ClearIcon fontSize="small" />}
              multiple
              disableCloseOnSelect
              id="user-selector"
              options={usersListOptions || []}
              filterSelectedOptions
              value={selectedUsers}
              onChange={handleShareChange}
              getOptionKey={({ kc_uid }) => kc_uid}
              getOptionLabel={({ first_name, last_name, kc_uid }) =>
                `${first_name} ${last_name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type or select a user"
                  placeholder="Type or select a user"
                />
              )}
            />
            {shouldSave && (
              <Alert
                sx={{
                  '& .MuiAlert-icon': {
                    alignItems: 'center',
                  },
                  marginBottom: 2,
                }}
                severity="warning"
              >
                To update your sharing preferences, please save your changes.
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            disabled={!shouldSave || conversationShareIsPending}
            autoFocus
            variant="contained"
            onClick={handleSave}
          >
            {isInitialShare ? 'Share' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
