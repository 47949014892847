import * as React from 'react';
import { UserContext } from '../context/userContext';
import { conversationService } from '../api';

import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Action } from './Action';
import { ShareConversation } from '../molecules';

interface Props {
  uid?: string;
}

export const ConversationCommonActionsIcons: React.FC<Props> = ({
  uid = '',
}) => {
  const { loggedIn } = React.useContext(UserContext);
  const { conversationDelete, getConversation } = conversationService();
  const navigate = useNavigate();

  if (!uid) {
    return null;
  }

  const { actions } = getConversation(uid);

  const deleteConversation = () => {
    conversationDelete
      .mutateAsync({
        uid,
      })
      .catch(() => {})
      .finally(() => {
        navigate('/');
      });
  };

  return loggedIn ? (
    <>
      {actions?.includes('share:conversation') && (
        <ShareConversation conversationId={uid} />
      )}
      {actions?.includes('delete:conversation') && (
        <Action
          actionLabel={'Delete'}
          icon={<DeleteForeverIcon />}
          confirmText="Are you sure you want to delete this Conversation?"
          confirmTitle="Deleting Conversation"
          onAction={deleteConversation}
        />
      )}
    </>
  ) : null;
};
