import * as React from 'react';
import { getListWithPrefix } from '../tools';
import { aiFlowService } from '../api';

interface Props {
  slug?: string;
}

export const AiFlowTextList: React.FC<Props> = ({ slug }) => {
  const { aiFlowList } = aiFlowService();

  const name = aiFlowList?.find((item) => item.slug === slug)?.name || '';

  return <>{getListWithPrefix('AI flow', [name])}</>;
};
