// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { api } from '../client';
import {
  PostConversationUidQaQuidFeedbackReq,
  CommonStatusRes,
  PostConversationUidQaQuidSourceDocumentFeedbackReq,
  PostMissingDocumentReq,
} from '../types/';
import { useSnackbar } from 'notistack';

const postConversationFeedback = ({
  uid,
  quid,
  comment,
  lickert,
}: PostConversationUidQaQuidFeedbackReq) => {
  return api.post<AxiosError, CommonStatusRes>(
    `/v1/conversation/${uid}/qa/${quid}/feedback`,
    { comment, lickert },
  );
};

const postSourceDocumentFeedback = ({
  uid,
  quid,
  uc_doc_id,
  issue,
  comment,
  like,
}: PostConversationUidQaQuidSourceDocumentFeedbackReq) => {
  return api.post<AxiosError, CommonStatusRes>(
    `/v1/conversation/${uid}/qa/${quid}/source-document/feedback`,
    { uc_doc_id, issue, comment, like },
  );
};

const postMissingDocument = (data: PostMissingDocumentReq) => {
  return api.post<AxiosError, CommonStatusRes>(`/v1/missing-document`, data);
};

export const feedbackService = () => {
  const { enqueueSnackbar } = useSnackbar();

  const conversationFeedbackMutation = useMutation({
    mutationFn: postConversationFeedback,
    onError: ({ message }) => {
      enqueueSnackbar(`postConversationFeedback error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Conversation feedback submitted successfully.', {
        variant: 'success',
      });
    },
  });

  const documentFeedbackMutation = useMutation({
    mutationFn: postSourceDocumentFeedback,
    onError: ({ message }) => {
      enqueueSnackbar(`postSourceDocumentFeedback error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Source document feedback submitted successfully.', {
        variant: 'success',
      });
    },
  });

  const missingDocumentFeedbackMutation = useMutation({
    mutationFn: postMissingDocument,
    onError: ({ message }) => {
      enqueueSnackbar(`postMissingDocument error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Missing document report submitted successfully.', {
        variant: 'success',
      });
    },
  });

  return {
    sendConversationFeedback: conversationFeedbackMutation.mutateAsync,
    sendDocumentFeedback: documentFeedbackMutation.mutateAsync,
    sendMissingDocumentFeedback: missingDocumentFeedbackMutation.mutateAsync,
    isPending:
      conversationFeedbackMutation.isPending ||
      documentFeedbackMutation.isPending ||
      missingDocumentFeedbackMutation.isPending,
  };
};
