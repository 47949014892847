import * as React from 'react';

import { notificationService } from '../api';
import { closeSnackbar, SnackbarKey, useSnackbar } from 'notistack';
import { NotificationListItem, NotificationResourceTypes } from '../types';
import {
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { UserContext } from '../context/userContext';

interface Props {}
type Actiondata = {
  uid: string;
  id: string | null;
  type: NotificationResourceTypes | null;
};
type LabelMap = {
  [key in NotificationResourceTypes]: string;
} & { null?: string };

const actionLabelMap: LabelMap = {
  conversation: 'Open conversation',
  review: 'Open review',
  null: 'Mark as read',
};

export const ShowSystemNotification: React.FC<Props> = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loggedIn } = React.useContext(UserContext);
  const navigate = useNavigate();
  const {
    systemNotifications,
    systemNotificationsRefetch,
    clearSystemNotification,
  } = notificationService();

  const showMessages = () => {
    if (systemNotifications) {
      systemNotifications.map(showMessage);
    }
  };

  const handleShowMessages = () => {
    systemNotificationsRefetch().then(showMessages);
  };

  React.useEffect(() => {
    if (systemNotifications?.length) {
      showMessages();
    }
  }, [systemNotifications]);

  const action =
    ({ uid, id, type }: Actiondata) =>
    (snackbarId: SnackbarKey) => {
      const hasSource = !!(id && type);
      const handleClick = () => {
        clearSystemNotification({ uid });
        if (hasSource) {
          navigate(`/${type}/${id}`);
        }
        closeSnackbar(snackbarId);
      };

      return (
        <>
          <Button onClick={handleClick} size="small">
            {hasSource ? actionLabelMap[type] : 'Mark as read'}
          </Button>
        </>
      );
    };

  const showMessage = (
    {
      subject,
      body,
      resource_uid: id,
      resource_type: type,
      uid,
    }: NotificationListItem,
    index: number,
  ) => {
    return enqueueSnackbar(
      <Stack>
        <strong>{subject}</strong>
        <span>{body}</span>
      </Stack>,
      {
        preventDuplicate: true,
        key: [subject, body, uid, id, type, uid].join(),
        variant: 'info',
        action: action({ uid, id, type }),
        autoHideDuration: 12000 + index * 1000,
      },
    );
  };

  if (!loggedIn) {
    return null;
  }

  return (
    <Badge badgeContent={systemNotifications?.length} color="primary">
      <NotificationsIcon
        onClick={handleShowMessages}
        sx={{ cursor: 'pointer', color: 'white' }}
      />
    </Badge>
  );
};

export const SystemNotificationList: React.FC<Props> = ({}) => {
  const { loggedIn } = React.useContext(UserContext);
  const navigate = useNavigate();
  const { systemNotifications } = notificationService();

  const Action = ({ uid, id, type }: Actiondata) => {
    const hasSource = !!(id && type);
    const handleClick = () => {
      // clearSystemNotification({ uid });
      if (hasSource) {
        navigate(`/${type}/${id}`);
      }
    };

    if (!hasSource) {
      return null;
    }
    return (
      <ListItemButton onClick={handleClick}>
        {actionLabelMap[type]}
      </ListItemButton>
    );
  };

  const showMessage = (
    {
      subject,
      body,
      resource_uid: id,
      resource_type: type,
      uid,
    }: NotificationListItem,
    index: number,
  ) => {
    return (
      <ListItem
        sx={{ borderBottom: '1px solid #dfdfdf', paddingLeft: 3 }}
        key={`item-${id}-${type}`}
      >
        <ListItemText
          primary={
            <Stack>
              <strong>{subject}</strong>
              <span>{body}</span>
            </Stack>
          }
        />
        <Box>
          <Action id={id} type={type} uid={uid} />
        </Box>
      </ListItem>
    );
  };

  if (!loggedIn) {
    return null;
  }

  return <List dense>{systemNotifications?.map(showMessage)}</List>;
};
