import * as React from 'react';
import Box from '@mui/material/Box';

import { Toolbar, useMediaQuery } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { grey } from '@mui/material/colors';
import * as amplitude from '@amplitude/analytics-browser';
import { SideMenu } from './molecules';
import {
  ConversationPage,
  DashboardPage,
  ConversationsPage,
  LibraryPage,
  ReviewPage,
  SettingsPage,
  ReviewRequestsPage,
} from './components';
// import theme from './theme';

if (process.env.REACT_APP_AMPLITUDE_KEY) {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY || '', {
    autocapture: true,
    fetchRemoteConfig: true,
  });
}

export default function App() {
  // const isMD = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      preventDuplicate
      maxSnack={5}
      autoHideDuration={2000} // 2s
      // style={{ maxWidth: isMD ? '90%' : '50%' }}
    >
      <React.Suspense fallback="Loading">
        <Box sx={{ display: 'flex' }}>
          <SideMenu />

          <Box
            component="main"
            flexGrow={1}
            minHeight="100vh"
            bgcolor={grey[300]}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route
                path="conversation/:conversationId"
                element={<ConversationPage />}
              />
              <Route path="review/:reviewId" element={<ReviewPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="library" element={<LibraryPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="explore" element={<ConversationsPage />} />
              <Route path="review-requests" element={<ReviewRequestsPage />} />
              {/* <Route path="research" element={<ResearchPage />} /> */}
            </Routes>
          </Box>
        </Box>
      </React.Suspense>
    </SnackbarProvider>
  );
}
