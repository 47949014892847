import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExploreIcon from '@mui/icons-material/Explore';
import ChatIcon from '@mui/icons-material/Chat';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { ReactComponent as LogoIcon } from '../icons/logo.svg';

import { NavLink } from 'react-router-dom';

export const KnowledgeMenu: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return loggedIn ? (
    <>
      <ListItem onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <LogoIcon height={20} width={24} fill="#fff" />
        </ListItemIcon>
        <ListItemText primary="Knowledge" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense disablePadding>
          <ListItem sx={{ pl: 3 }} component={NavLink} to={'/explore'}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ExploreIcon />
            </ListItemIcon>
            <ListItemText primary="Conversations" />
          </ListItem>
          <ListItem sx={{ opacity: 0.5, pl: 3 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Research" />
          </ListItem>
          <ListItem sx={{ pl: 3, opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ArticleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Topics" />
          </ListItem>
          <ListItem sx={{ pl: 3, opacity: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <MenuBookOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Learning" />
          </ListItem>
        </List>
      </Collapse>
    </>
  ) : null;
};
