import * as React from 'react';
import { UserContext } from '../context/userContext';
import { Collapse, IconButton, Paper, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { ConversationItemQa } from '../types';
import {
  ContactSme,
  FeedBack,
  FollowUpQuestions,
  InconclusiveAnswer,
  MuiMarkDown,
  References,
  TitleSection,
} from '.';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

interface Props {
  data: ConversationItemQa;
  onFollowUpQuestion: (question: string) => void;
  conversationId: string;
  number: number;
  canContactSME: boolean;
  collapsed?: boolean;
}

export const ConversationQa: React.FC<Props> = ({
  data: {
    answer,
    question,
    uid: quid,
    source_documents,
    review_uid,
    follow_up_questions,
    annotations,
    feedback_lickert,
    feedback_comment,
    created_at,
  },
  onFollowUpQuestion,
  conversationId,
  number,
  canContactSME,
  collapsed = false,
}) => {
  const { loggedIn } = React.useContext(UserContext);
  const [isExpanded, setIsExpanded] = React.useState(!collapsed);

  React.useEffect(() => {
    setIsExpanded(!collapsed);
  }, [collapsed]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return loggedIn ? (
    <Stack key={quid} direction="row" gap={1}>
      {number}
      <Paper sx={{ width: '100%' }}>
        <Stack spacing={2} padding={2}>
          <Stack direction="row" justifyContent="space-between">
            <TitleSection
              title={question}
              icon={<AccountCircleIcon fontSize="large" />}
            ></TitleSection>
            <IconButton onClick={handleToggle} aria-label="expand" size="small">
              {isExpanded ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
          </Stack>
          {/* {isExpanded && <hr />} */}
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Grid container borderTop="1px solid #dfdfdf" padding={2}>
              <Grid size={6} lineHeight={2} pr={2}>
                <Stack gap={2}>
                  <TitleSection
                    title={format(created_at, 'MMMM do, yyyy HH:mm')}
                    icon={<LocalPoliceIcon fontSize="large" />}
                  >
                    <MuiMarkDown key={quid} mdContent={answer} />
                  </TitleSection>

                  <InconclusiveAnswer annotation={annotations} />
                </Stack>
              </Grid>
              <Grid size={6} pl={2} borderLeft="1px solid #dfdfdf">
                <Stack gap={2}>
                  <FollowUpQuestions
                    questions={follow_up_questions}
                    onClick={onFollowUpQuestion}
                  />

                  <References
                    cuid={conversationId}
                    quid={quid}
                    sourceDocuments={source_documents}
                  />

                  <FeedBack
                    uid={conversationId}
                    quid={quid}
                    defaultValue={feedback_lickert}
                    defaultComment={feedback_comment}
                  />

                  <ContactSme
                    canContactSME={canContactSME}
                    quid={quid}
                    reviewUid={review_uid}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Collapse>
        </Stack>
      </Paper>
    </Stack>
  ) : null;
};
