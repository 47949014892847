import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../client';
import {
  CustomLibraryDocument,
  GetLibrarySlugReq,
  GetLibrarySlugRes,
  GetRetrievalBundlesRes,
} from '../types/';
import React from 'react';
import { UserContext } from '../context/userContext';

const getRetrievalBundles = () => {
  return api.get<AxiosError, GetRetrievalBundlesRes>('/v1/retrieval-bundles');
};

const getLibrarySlug = ({ slug }: GetLibrarySlugReq) => {
  return api.get<AxiosError, GetLibrarySlugRes>(`/v1/library/${slug}`);
};

export const libraryService = () => {
  const { loggedIn } = React.useContext(UserContext);

  const retrievalBundles = useQuery({
    queryKey: ['GET_RETRIEVAL_BUNDLES'],
    queryFn: getRetrievalBundles,
    enabled: loggedIn,
    staleTime: Infinity,
  });

  const getLibrary = (slug = '') => {
    const { data } = useQuery({
      queryKey: ['GET_LIBRARY_SLUG', slug],
      queryFn: () => getLibrarySlug({ slug }),
      enabled: !!slug,
      staleTime: Infinity,
    });

    const libraryDocuments = data?.data.libraries?.[0].library.docs || {};

    return Object.keys(libraryDocuments).map((k) => ({
      ...libraryDocuments[k],
      id: libraryDocuments[k].uc_doc_id,
    })) as CustomLibraryDocument[] | null[];
  };

  return {
    retrievalBundlesTagsList: retrievalBundles.data?.data.retrieval_bundles.map(
      ({ slug, library }) => ({
        slug,
        name: library.name,
        description: library.description,
      }),
    ),
    libraryTagList: retrievalBundles.data?.data.retrieval_bundles.map(
      ({ library: { name, slug } }) => ({ slug, name }),
    ),
    getLibrary,
  };
};
