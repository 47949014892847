import * as React from 'react';
import Box from '@mui/material/Box';
import { UserContext } from '../context/userContext';
import {
  AppBar,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { conversationService, libraryService, userService } from '../api';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { BotInput, ConversationQa, ListSkeleton } from '../molecules';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Helmet } from 'react-helmet';
import { gatBundleNameList } from '../tools';
import { ConversationCommonActionsIcons } from '../atoms';

interface Props {
  uid?: string | null;
}

export const ConversationPage: React.FC<Props> = ({ uid }) => {
  const { loggedIn } = React.useContext(UserContext);
  const listRef = useRef<HTMLDivElement>(null);
  const { retrievalBundlesTagsList = [] } = libraryService();

  const { conversationId = uid } = useParams();
  const isInlineView = !!uid;
  const { usersList } = userService();

  if (!conversationId) {
    return null;
  }

  const {
    sendQuestion,
    conversationIsPending,
    questionIsPending,
    updateConversation,
    getConversation,
  } = conversationService();

  const {
    qas: conversationQas,
    retrieval_bundle_slugs,
    name: questionAsked,
    owner_uid,
    is_owned: isOwner,
    description,
    actions,
  } = getConversation(conversationId);

  const chips = gatBundleNameList(
    retrievalBundlesTagsList,
    retrieval_bundle_slugs,
  );
  const author = usersList?.find(({ kc_uid }) => owner_uid === kc_uid);
  const authorName = `${author?.first_name} ${author?.last_name} `;

  const [askedQuestion, setAskedQuestion] = React.useState('');
  const [editingTitle, setEditingTitle] = React.useState(false);
  const [title, setTitle] = React.useState(questionAsked);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  useEffect(() => {
    setTitle(questionAsked);
  }, [questionAsked]);

  // After question returned
  useEffect(() => {
    setTimeout(() => {
      listRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
    setAskedQuestion('');
  }, [conversationQas]);

  const handleSubmitQuestion = (question: string) => {
    setAskedQuestion(question);
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });

    sendQuestion({
      uid: conversationId,
      prompt: question,
    });
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleRenameTitle = () => {
    setEditingTitle(!editingTitle);
  };

  const handleSubmitTitle = () => {
    setEditingTitle(false);
    if (title) {
      updateConversation({
        uid: conversationId,
        name: title,
        description,
      });
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleFollowUpQuestion = handleSubmitQuestion;

  const sortedAnswers = (conversationQas || []).sort(
    ({ created_at: cr1 }, { created_at: cr2 }) => {
      return cr1.localeCompare(cr2);
    },
  );

  return loggedIn ? (
    <>
      <Helmet>
        <title>{`Conversation: ${questionAsked}`}</title>
      </Helmet>

      <AppBar position="sticky">
        <Toolbar
          sx={{
            justifyContent: 'space-between',

            background:
              isOwner !== undefined && isOwner ? '#dfdfdf' : '#bedbf3',
          }}
        >
          {editingTitle && (
            <TextField
              sx={{
                background: 'white',
                marginRight: '16px',
              }}
              defaultValue={questionAsked}
              hiddenLabel
              fullWidth
              multiline
              size="small"
              value={title}
              onChange={handleTitleChange}
              id="title"
            />
          )}
          {!editingTitle && (
            <Stack spacing={1} direction="row" alignItems="center">
              {!!author && (
                <Box>
                  <Tooltip title={questionAsked}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'black',
                        maxWidth: '600px',
                        minWidth: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1,
                      }}
                    >
                      {questionAsked}
                    </Typography>
                  </Tooltip>
                  {!isOwner && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'black',
                      }}
                    >
                      Shared by author: <strong>{authorName}</strong>
                    </Typography>
                  )}
                </Box>
              )}

              {!!chips?.length &&
                chips.map((chipLabel) => (
                  <Chip
                    key={chipLabel}
                    label={chipLabel}
                    size="small"
                    variant="outlined"
                    sx={{
                      background: 'white',
                    }}
                  />
                ))}
            </Stack>
          )}
          <Stack spacing={0} direction="row" alignItems="center">
            <Box pr={1}>
              {actions?.includes('rename:conversation') && !editingTitle && (
                <Button
                  size="small"
                  onClick={handleRenameTitle}
                  variant="contained"
                >
                  Rename
                </Button>
              )}
              {editingTitle && (
                <Button
                  size="small"
                  onClick={handleSubmitTitle}
                  variant="contained"
                >
                  Save
                </Button>
              )}
            </Box>

            <ConversationCommonActionsIcons uid={conversationId} />
            <IconButton
              sx={{
                color: 'black',
              }}
              onClick={handleToggle}
              size="small"
            >
              {isCollapsed ? (
                <KeyboardDoubleArrowDownIcon />
              ) : (
                <KeyboardDoubleArrowUpIcon />
              )}
            </IconButton>
          </Stack>
        </Toolbar>
        {/* <Toolbar />
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                // onClick={handleDrawerToggle}KeyboardDoubleArrowUpIcon
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <KeyboardDoubleArrowUpIcon />
              </IconButton>
            </Toolbar> */}
      </AppBar>
      <Box component="main" p={2} bgcolor={grey[300]}>
        {conversationIsPending && (
          <Paper>
            <Stack padding={2}>
              <ListSkeleton numOfItems={12} width="100%" />
            </Stack>
          </Paper>
        )}

        <Box position="sticky" bottom={16}></Box>
        {!conversationIsPending && (
          <Stack spacing={2}>
            <Stack spacing={2} ref={listRef}>
              {sortedAnswers?.map((data, i) => (
                <ConversationQa
                  data={data}
                  key={data.uid}
                  conversationId={conversationId}
                  canContactSME={
                    !!actions?.includes('contact_sme_on:conversation')
                  }
                  onFollowUpQuestion={handleFollowUpQuestion}
                  number={i + 1}
                  collapsed={isCollapsed}
                ></ConversationQa>
              ))}
            </Stack>
            <BotInput
              value={askedQuestion}
              loading={questionIsPending}
              onSubmit={handleSubmitQuestion}
            />
          </Stack>
        )}
      </Box>
    </>
  ) : null;
};
