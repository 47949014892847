// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';
import {
  CommonStatusRes,
  GetSystemNotificationRes,
  PatchSystemNotificationUid,
  PostSystemNotificationReq,
} from '../types/';
import React from 'react';
import { UserContext } from '../context/userContext';
import { enqueueSnackbar } from 'notistack';
import { queryClient } from '..';

const getSystemNotification = () => {
  return api.get<AxiosError, GetSystemNotificationRes>(
    '/v1/system-notification',
  );
};

const postSystemNotification = (reqData: PostSystemNotificationReq) => {
  return api.post<AxiosError, CommonStatusRes>(
    `/v1/system-notification`,
    reqData,
  );
};

const patchSystemNotificationUid = ({ uid }: PatchSystemNotificationUid) => {
  return api.patch<AxiosError, CommonStatusRes>(
    `/v1/system-notification/${uid}`,
  );
};

export const notificationService = () => {
  const { loggedIn } = React.useContext(UserContext);

  const systemNotifications = useQuery({
    queryKey: ['GET_SYSTEM_NOTIFICATIONS'],
    queryFn: getSystemNotification,
    enabled: loggedIn,
    staleTime: Infinity,
    // refetchInterval: 20000, refetch every 20s TODO: ask to implement
  });

  const sendSystemNotificationMutation = useMutation({
    mutationFn: postSystemNotification,
    onError: ({ message }) => {
      enqueueSnackbar(`postSystemNotification error:  ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated notification.', {
        variant: 'success',
      });
    },
  });

  const clearSystemNotificationMutation = useMutation({
    mutationFn: patchSystemNotificationUid,
    onError: ({ message }) => {
      enqueueSnackbar(`patchSystemNotificationUid error:  ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['GET_SYSTEM_NOTIFICATIONS'],
      });
      enqueueSnackbar('Successfully cleared notification.', {
        variant: 'success',
      });
    },
  });

  return {
    systemNotifications: systemNotifications.data?.data.notifications,
    systemNotificationsRefetch: systemNotifications.refetch,
    sendSystemNotification: sendSystemNotificationMutation.mutate,
    clearSystemNotification: clearSystemNotificationMutation.mutate,
  };
};
