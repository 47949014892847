import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  Box,
  Card,
  CardContent,
  Stack,
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet';
import { MyRequestsTable, MyReviewsTable } from '../molecules';

export const ReviewRequestsPage: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);

  return loggedIn ? (
    <>
      <Helmet>
        <title>Review Requests</title>
      </Helmet>

      <AppBar position="sticky">
        <Toolbar
          sx={{
            background: '#dfdfdf',
          }}
        >
          <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <Typography
              variant="h6"
              sx={{
                color: 'black',
                lineHeight: 1,
              }}
            >
              Review Requests
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box component="main" p={2}>
        <Stack direction="row" spacing={2}>
          <Card sx={{ flex: 1, minWidth: 400 }}>
            <CardContent>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'black',
                  lineHeight: 1,
                }}
                pb={1}
              >
                Asked By Me
              </Typography>
              <MyRequestsTable />
            </CardContent>
          </Card>
          <Card sx={{ flex: 2, minWidth: 400 }}>
            <CardContent>
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'black',
                  lineHeight: 1,
                }}
                pb={1}
              >
                Assigned To Me
              </Typography>
              <MyReviewsTable />
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </>
  ) : null;
};
