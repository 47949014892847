import * as React from 'react';
import { Button } from '@mui/material';

import { reviewService } from '../api';
import { NavLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDistance, parseISO } from 'date-fns';

export const MyReviewsTable: React.FC = () => {
  const { assignedReviewsList } = reviewService();

  const columns: GridColDef[] = [
    {
      field: 'requesterNote',
      headerName: "Requestor's Comment",
      flex: 1,
      renderCell: (params) => (
        <Button
          component={NavLink}
          to={`/review/${params.row.id}`}
          sx={{
            color: 'black',
            fontWeight: 'normal',
            textTransform: 'none',
            justifyContent: 'left',
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'myResponse',
      headerName: 'My Response',
      flex: 1,
      renderCell: ({ value, row }) => (
        <Button
          size="small"
          variant={value ? 'text' : 'outlined'}
          component={NavLink}
          to={`/review/${row.id}`}
          sx={{
            color: 'black',
            fontWeight: 'normal',
            textTransform: 'none',
            justifyContent: 'left',
            lineHeight: 1,
          }}
        >
          {value || 'Respond'}
        </Button>
      ),
    },

    {
      field: 'requester',
      headerName: 'Requested By',
      width: 150,
    },

    {
      field: 'updated',
      headerName: 'Updated',
      width: 140,
      type: 'string',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>
          {formatDistance(parseISO(params.value + 'Z'), new Date(), {
            addSuffix: true,
          })}
        </>
      ),
    },
  ];

  const paginationModel = { page: 0, pageSize: 10 };

  const rows = [
    ...(assignedReviewsList?.map(
      ({ uid, reviewer_note, requester_note, requester_name, updated_at }) => ({
        id: uid,
        requesterNote: requester_note,
        myResponse: reviewer_note,
        requester: requester_name,
        updated: updated_at,
      }),
    ) || []),
  ];

  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel } }}
      disableRowSelectionOnClick
      sx={{ border: 0 }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
};
