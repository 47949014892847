export const getDocumentTitleFromSource = (value: string) =>
  value
    .split('/')
    .slice(-1)[0]
    .replaceAll(/\.html|\.pdf|_|-/gi, ' ')
    .trim()
    .slice(0, 40);

export const getDocumentUrl = (
  url: string,
  queryTemplate: string | null,
  page: number | null,
) => {
  if (queryTemplate && page !== null) {
    const query = queryTemplate?.replace('%s_pg_num%', page.toString());
    return `${url}${query}`;
  }
  return url;
};

export const getListWithPrefix = (prefix: string, items: string[]) =>
  items ? `${prefix}: ${items.join(', ')}` : '';

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: 'pointer',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};
