type RetrievalBundlesItem = {
  slug: string;
  name: string;
};

export const gatBundleNameList = (
  retrievalBundlesTagsList?: RetrievalBundlesItem[],
  slugs?: string[],
) =>
  retrievalBundlesTagsList
    ?.filter((item) => slugs?.includes(item.slug))
    .map(({ name }) => name) || [];
