import * as React from 'react';
import { gatBundleNameList, getListWithPrefix } from '../tools';
import { libraryService } from '../api';

interface Props {
  slugs?: string[];
}

export const BundleTextList: React.FC<Props> = ({ slugs }) => {
  const { retrievalBundlesTagsList } = libraryService();

  const names = gatBundleNameList(retrievalBundlesTagsList, slugs);

  return <>{getListWithPrefix('Sources', names)}</>;
};
