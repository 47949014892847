import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';

interface Props {
  title: string;
  question: string;
  cancelLabel?: string;
  confirmLabel: string;
  show: boolean;
  onClick: (agree: boolean) => void;
}

export const Confirm: React.FC<Props> = ({
  title,
  question,
  cancelLabel = 'Cancel',
  confirmLabel,
  show,
  onClick,
}) => {
  const handleClose = () => {
    onClick(false);
  };

  const handleConfirm = () => {
    onClick(true);
  };

  return (
    <Dialog onClose={handleClose} open={show}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <DialogContentText>{question}</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelLabel}</Button>
        <Button autoFocus variant="contained" onClick={handleConfirm}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
