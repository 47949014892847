import * as React from 'react';
import { Button } from '@mui/material';

import { reviewService } from '../api';
import { NavLink } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDistance, parseISO } from 'date-fns';

export const MyRequestsTable: React.FC = () => {
  const { requestedReviewsList } = reviewService();

  const columns: GridColDef[] = [
    {
      field: 'note',
      headerName: 'My Comment',
      flex: 1,
      renderCell: (params) => (
        <Button
          component={NavLink}
          to={`/review/${params.row.id}`}
          sx={{
            color: 'black',
            fontWeight: 'normal',
            textTransform: 'none',
            justifyContent: 'left',
          }}
        >
          {params.value}
        </Button>
      ),
    },

    {
      field: 'assigned',
      headerName: 'Assigned To',
      width: 150,
    },

    {
      field: 'updated',
      headerName: 'Updated',
      width: 140,
      type: 'string',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>
          {formatDistance(parseISO(params.value + 'Z'), new Date(), {
            addSuffix: true,
          })}
        </>
      ),
    },
  ];

  const paginationModel = { page: 0, pageSize: 10 };

  const rows = [
    ...(requestedReviewsList?.map(
      ({ uid, requester_note, reviewer_name, updated_at }) => ({
        id: uid,
        note: requester_note,
        assigned: reviewer_name,
        updated: updated_at,
      }),
    ) || []),
  ];

  return (
    <DataGrid
      density="compact"
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel } }}
      disableRowSelectionOnClick
      sx={{ border: 0 }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
};
