import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import { reviewService, userService } from '../api';
import { ListSkeleton } from './Skeletons';
import { NavLink } from 'react-router-dom';
import { ReactComponent as NoRequestsIcon } from '../icons/noReviews.svg';

export const ReviewsMenu: React.FC = () => {
  const { isSME } = userService();
  const { loggedIn } = React.useContext(UserContext);

  const { requestedReviewsList, reviewsIsLoading, assignedReviewsList } =
    reviewService();

  // TODO: fix labeling
  const selectedList = isSME ? assignedReviewsList : requestedReviewsList;
  const list = selectedList?.slice(0, 5);

  return loggedIn ? (
    <>
      {reviewsIsLoading && <ListSkeleton contrast />}
      <List dense disablePadding>
        {list?.map(({ uid, requester_note }) => (
          <ListItem
            component={NavLink}
            to={`/review/${uid}`}
            key={uid}
            disablePadding
            sx={{ color: 'black' }}
          >
            <ListItemButton sx={{ pl: 3 }}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <PreviewIcon />
              </ListItemIcon>
              <ListItemText primary={requester_note} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <>
      <Box color="#808080" pl={3}>
        You have no review requests.
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={200}
        sx={{ opacity: 0.5 }}
      >
        <NoRequestsIcon height="150px" />
      </Box>
    </>
  );
};
