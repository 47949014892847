import * as React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import { TitleSection } from './Common';

interface Props {
  questions: string[] | null;
  onClick: (question: string) => void;
}

export const FollowUpQuestions: React.FC<Props> = ({ questions, onClick }) => {
  if (!questions?.length) {
    return null;
  }

  const handleFollowUpQuestion = (question: string) => () => {
    onClick(question);
  };

  return (
    <TitleSection
      title="Follow up question(s):"
      icon={<QuestionAnswerIcon fontSize="medium" />}
      contentPadding="24px"
    >
      <List disablePadding>
        {questions.map((question, i) => (
          <ListItemButton
            sx={{ p: 0.5 }}
            onClick={handleFollowUpQuestion(question)}
            key={i}
          >
            <ForkRightIcon sx={{ pr: 0.5 }} fontSize="medium" />
            <ListItemText primary={question} />
          </ListItemButton>
        ))}
      </List>
    </TitleSection>
  );
};
