import { alpha, createTheme, getContrastRatio } from '@mui/material/styles';

// Augment the palette to include a violet color
declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary'];
  }

  interface PaletteOptions {
    blue?: PaletteOptions['primary'];
  }
}

// Update the AppBar's color options to include a blue option
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    blue: true;
  }
}

const blueBase = '#101827';
const blueMain = alpha(blueBase, 0.7);
// A custom theme for this app
const theme = createTheme({
  typography: {
    fontSize: 14,
  },
  shape: {
    // borderRadius: 8,
  },

  palette: {
    blue: {
      main: blueBase,
      light: alpha(blueBase, 0.5),
      dark: alpha(blueBase, 0.9),
      contrastText: getContrastRatio(blueMain, '#fff') > 4.5 ? '#fff' : '#111',
    },

    mode: 'light',

    primary: {
      main: '#20458A',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.notistack-SnackbarContainer': {
          top: '70px',
          right: '10px',
        },
      },
    },
  },
});

export default theme;
