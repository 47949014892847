import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Collapse,
  FormControl,
  IconContainerProps,
  Rating,
  Stack,
  styled,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { feedbackService } from '../api';
import {
  FormContainer,
  CheckboxButtonGroup,
  TextareaAutosizeElement,
  useForm,
} from 'react-hook-form-mui';

interface FormInputs {
  comment: string;
  issues: string[];
}

const customIcons: {
  [index: string]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <ThumbUpIcon color="primary" />,
    label: 'Satisfied',
  },
  2: {
    icon: <ThumbDownIcon color="primary" />,
    label: 'Dissatisfied',
  },
};

const StyledRating = styled(Rating)(({ theme }) => ({
  gap: '4px',
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

interface Props {
  cuid: string;
  quid: string;
  ucDocId: string | null;
  onSubmitSuccess?: () => void;
}

export const DocumentReferenceFeedback: React.FC<Props> = ({
  cuid,
  quid,
  ucDocId,
  onSubmitSuccess,
}) => {
  const [voteValue, setVoteValue] = React.useState<number | null>(null);
  const { sendDocumentFeedback } = feedbackService();
  const formContext = useForm<FormInputs>({
    defaultValues: {
      issues: [],
      comment: '',
    },
  });
  const likeSelected = voteValue === 1;
  const disableSubmit = !voteValue;

  // if (!uid || !ucDocId) {
  if (!quid) {
    return null;
  }
  const { handleSubmit, clearErrors, formState, setValue, reset } = formContext;

  const handleSubmitFeedback = ({ comment, issues = [] }: FormInputs) => {
    sendDocumentFeedback({
      uid: cuid,
      quid,
      comment,
      issue: issues.join(', '),
      like: likeSelected,
      uc_doc_id: ucDocId || '',
    }).then(() => {
      reset();
      setVoteValue(null);
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    });
  };

  const handleRateChange = (e: React.SyntheticEvent, value: number | null) => {
    setVoteValue(value);
    if (value !== 2) {
      setValue('issues', []);
    }
    clearErrors();
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return (
      <Box sx={{ gap: '4px' }} {...other}>
        {customIcons[value].icon}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" gap={1} padding="8px 0">
        <strong>Please provide feedback for this reference:</strong>
        <StyledRating
          name="highlight-selected-only"
          defaultValue={2}
          max={2}
          IconContainerComponent={IconContainer}
          getLabelText={(value: number) => customIcons[value].label}
          highlightSelectedOnly
          onChange={handleRateChange}
          value={voteValue}
        />
      </Stack>
      <Collapse in={!disableSubmit} timeout="auto" unmountOnExit>
        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(handleSubmitFeedback)}
        >
          <FormControl fullWidth variant="outlined">
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <Stack
                  sx={{
                    '.MuiCheckbox-root': {
                      paddingTop: '6px',
                      paddingBottom: '6px',
                    },
                  }}
                  gap={0}
                >
                  <CheckboxButtonGroup
                    disabled={likeSelected || disableSubmit}
                    name="issues"
                    options={[
                      {
                        id: 'Reference not relevant',
                        label: 'Reference not relevant',
                      },
                      {
                        id: 'Reference incomplete',
                        label: 'Reference incomplete',
                      },
                      {
                        id: 'Reference outdated',
                        label: 'Reference outdated',
                      },
                    ]}
                  />
                </Stack>
                <Stack position="relative" flex={1}>
                  <TextareaAutosizeElement
                    rules={{
                      required: 'Please provide a comment',
                    }}
                    size="small"
                    label="Please provide more details and help us improve our future
                  references"
                    name="comment"
                    disabled={disableSubmit}
                    multiline
                    rows={4}
                  />
                </Stack>
              </Stack>
              <Box textAlign="right">
                <Button
                  disabled={!formState.isValid || disableSubmit}
                  type={'submit'}
                  variant="contained"
                >
                  Submit review
                </Button>
              </Box>
            </Stack>
          </FormControl>
        </FormContainer>
      </Collapse>
    </Box>
  );
};
