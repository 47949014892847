// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';
import {
  ConversationItemRes,
  ConversationUidReq,
  ConversationUidRes,
  ConversationUidPromptReq,
  ConversationPromptRes,
  ConversationPromptReq,
  CommonStatusRes,
  PatchConversationUidReq,
  ConversationUidDeleteReq,
  PutConversationUidShareReq,
  GetConversationUidShareReq,
  GetConversationUidShareRes,
  PatchConversationUidRes,
} from '../types/';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { UserContext } from '../context/userContext';
import { queryClient } from '..';

const getConversationsList = () => {
  return api.get<AxiosError, ConversationItemRes>('/v1/conversation');
};

const getSharedConversationsList = () => {
  return api.get<AxiosError, ConversationItemRes>('/v1/shared-conversation');
};

const getConversationUid = ({ uid }: ConversationUidReq) => {
  return api.get<AxiosError, ConversationUidRes>(`/v1/conversation/${uid}`);
};

const getConversationUidShare = ({ uid }: GetConversationUidShareReq) => {
  return api.get<AxiosError, GetConversationUidShareRes>(
    `/v1/conversation/${uid}/share`,
  );
};

const patchConversation = ({ uid, ...data }: PatchConversationUidReq) => {
  return api.patch<AxiosError, PatchConversationUidRes>(
    `/v1/conversation/${uid}`,
    data,
  );
};

const postQuestion = ({ uid, prompt }: ConversationUidPromptReq) => {
  return api.post<AxiosError, ConversationPromptRes>(
    `/v1/conversation/${uid}/prompt`,
    { prompt },
  );
};

const putShare = ({ uid, users }: PutConversationUidShareReq) => {
  return api.put<AxiosError, CommonStatusRes>(`/v1/conversation/${uid}/share`, {
    users,
  });
};

const deleteConversation = ({ uid }: ConversationUidDeleteReq) => {
  return api.delete<AxiosError, CommonStatusRes>(`/v1/conversation/${uid}`);
};

const postQuestionCreateConversation = (data: ConversationPromptReq) => {
  return api.post<AxiosError, ConversationPromptRes>(
    '/v1/conversation/prompt',
    data,
  );
};

export const promptService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const questionMutation = useMutation({
    mutationFn: postQuestionCreateConversation,
    onError: ({ message }) => {
      enqueueSnackbar(`postQuestion error: ${message}`, { variant: 'error' });
    },
    onSuccess: (res: ConversationPromptRes) => {
      queryClient.refetchQueries({ queryKey: ['GET_CONVERSATION_LIST'] });
      navigate(`/conversation/${res.data.uid}`);
    },
  });

  return {
    questionIsPending: questionMutation.isPending,
    sendQuestion: questionMutation.mutate,
  };
};

export const conversationService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loggedIn } = React.useContext(UserContext);

  const conversationList = useQuery({
    queryKey: ['GET_CONVERSATION_LIST'],
    queryFn: getConversationsList,
    enabled: loggedIn,
    staleTime: Infinity,
  });

  const sharedConversationList = useQuery({
    queryKey: ['GET_SHARED_CONVERSATION_LIST'],
    queryFn: getSharedConversationsList,
    enabled: loggedIn,
    staleTime: Infinity,
  });

  const conversationMutation = useMutation({
    mutationFn: getConversationUid,
    onError: ({ message }) => {
      enqueueSnackbar(`getConversationUid error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {},
  });

  const getConversation = (cid = '') => {
    const { data } = useQuery({
      queryKey: ['GET_CONVERSATION', cid],
      queryFn: () => getConversationUid({ uid: cid }),
      staleTime: Infinity,
    });

    return {
      ...(data?.data || {}),
    };
  };

  const getConversationShare = (cid = '') => {
    const { data } = useQuery({
      queryKey: ['GET_CONVERSATION_SHARE', cid],
      queryFn: () => getConversationUidShare({ uid: cid }),
      staleTime: Infinity,
    });

    return {
      ...(data?.data || {}),
    };
  };

  const updateConversationMutation = useMutation({
    mutationFn: patchConversation,
    onError: (ajaxError) => {
      const error = (ajaxError as any)?.response?.data;
      console.error(error);
      const message: string = error?.detail?.[0]?.msg || '';

      enqueueSnackbar(`patchConversationUid error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: ({ data: { name, description, uid } }) => {
      // Update conversation lists
      queryClient.refetchQueries({ queryKey: ['GET_CONVERSATION_LIST'] });
      queryClient.setQueryData(
        ['GET_CONVERSATION', uid],
        (conversation?: ConversationUidRes) => {
          // TODO: add typescript definition - e.g. check name
          return {
            data: {
              ...conversation?.data,
              name,
              description,
            },
          };
        },
      );
      enqueueSnackbar('Successfully updated conversation.', {
        variant: 'success',
      });
    },
  });

  const conversationDelete = useMutation({
    mutationFn: deleteConversation,
    onError: (ajaxError) => {
      const error = (ajaxError as any)?.response?.data;
      console.error(error);
      const message: string = error?.detail?.[0]?.msg || '';

      enqueueSnackbar(`deleteConversationUid error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {
      // Update related lists, to remove all related items linked to the deleted conversation
      queryClient.refetchQueries({ queryKey: ['GET_ASSIGNED_REVIEWS_LIST'] });
      queryClient.refetchQueries({ queryKey: ['GET_REQUESTED_REVIEWS_LIST'] });
      queryClient.refetchQueries({ queryKey: ['GET_CONVERSATION_LIST'] });

      enqueueSnackbar('Successfully deleted conversation.', {
        variant: 'success',
      });
    },
  });

  const questionMutation = useMutation({
    mutationFn: postQuestion,
    onError: ({ message }) => {
      enqueueSnackbar(`postQuestion error: ${message}`, { variant: 'error' });
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        ['GET_CONVERSATION', data.conversation_uid],
        (conversation?: ConversationUidRes) => {
          // TODO: add type
          return {
            data: {
              ...conversation?.data,
              qas: [...(conversation?.data.qas || []), data],
            },
          };
        },
      );
    },
  });

  const shareMutation = useMutation({
    mutationFn: putShare,
    onError: ({ message }) => {
      enqueueSnackbar(`putShare error: ${message}`, { variant: 'error' });
    },
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['GET_CONVERSATION_SHARE'] });
      enqueueSnackbar('Successfully shared conversation.', {
        variant: 'success',
      });
    },
  });

  return {
    conversationList: conversationList.data?.data.conversations,
    sharedConversationList: sharedConversationList.data?.data.conversations,
    conversationIsLoading:
      conversationList.isLoading || sharedConversationList.isLoading,
    conversationIsPending: conversationMutation.isPending,
    questionIsPending: questionMutation.isPending,
    sendQuestion: questionMutation.mutate,
    conversationShare: shareMutation.mutateAsync,
    conversationShareIsPending: shareMutation.isPending,
    conversationDelete,
    updateConversation: updateConversationMutation.mutate,
    getConversation,
    getConversationShare,
  };
};

// CHECK
// https://medium.com/@amavictor/how-to-use-react-query-axios-and-a-custom-request-processor-to-transform-your-data-2a9f0c9f5bf0
