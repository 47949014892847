import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { authService, userService } from '../api';
import { useEffect } from 'react';
import { UserContext } from '../context/userContext';
import { useNavigate } from 'react-router-dom';
import { stringAvatar } from '../tools';
import zIndex from '@mui/material/styles/zIndex';

interface Props {
  onRegisterClick?: () => void;
  onResetClick?: () => void;
}

export const Login: React.FC<Props> = ({ onRegisterClick, onResetClick }) => {
  const { login, logout } = authService();
  const { userInfo, isDeveloper, isPending } = userService();
  const [user, setUser] = React.useState<null | string>('');
  const [password, setPassword] = React.useState<null | string>('');
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setShowLoginForm(true);
  const handleClose = () => {
    setShowLoginForm(false);
    setAnchorEl(null);
  };

  const { loggedIn, refreshToken, accessToken } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userName = `${userInfo?.first_name} ${userInfo?.last_name}`;
  useEffect(() => {
    if (loggedIn) {
      handleClose();
    } else {
      setShowLoginForm(true);
    }
  }, [loggedIn]);

  const handleLogin = () => {
    if (user && password) {
      login({ user, password });
    }
  };

  const handleRegister = () => {
    if (onRegisterClick) {
      setShowLoginForm(false);
      onRegisterClick();
    }
  };

  const handleReset = () => {
    if (onResetClick) {
      setShowLoginForm(false);
      onResetClick();
    }
  };

  const handleLogout = () => {
    logout({ refresh_token: refreshToken });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideLogin = !(user && password);

  return (
    <>
      {!loggedIn && (
        <Button color="inherit" onClick={handleOpen} variant="text">
          Log In
        </Button>
      )}

      {loggedIn && (
        <>
          {isPending && (
            <Avatar
              sx={{
                bgcolor: 'white',
              }}
              children={<CircularProgress size="30px" />}
            />
          )}
          {!isPending && (
            <Avatar
              alt={userName}
              onClick={handleMenu}
              {...stringAvatar(userName)}
            />
          )}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              zIndex: zIndex.snackbar + 1,
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled onClick={handleClose}>
              <Box>
                {userName}
                <br />
                Type: {userInfo?.user_type}
                <br />
                {isDeveloper && 'Is developer'}
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/settings');
              }}
            >
              Areas settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </>
      )}
      <Dialog onClose={handleClose} open={showLoginForm}>
        <DialogTitle>Sign in</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              Welcome user, please sign in to continue
            </DialogContentText>
            <TextField
              value={user}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUser(event.target.value);
              }}
              id="user"
              label="User name"
            />
            <TextField
              type="password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              id="password"
              label="Password"
            />
            <Stack>
              <Box>
                You don't have an account,
                <Button onClick={handleRegister} size="small" variant="text">
                  Register
                </Button>
              </Box>
              <Box>
                Did you forgot you password,
                <Button onClick={handleReset} size="small" variant="text">
                  Reset password
                </Button>
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            autoFocus
            disabled={hideLogin}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
