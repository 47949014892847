import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { api } from '../client';
import { GetAIFlowRes } from '../types/';
import { userService } from '.';

const getAiFlow = () => {
  return api.get<AxiosError, GetAIFlowRes>('/v1/aiflow');
};

export const aiFlowService = () => {
  const { isDeveloper } = userService();

  const aiFlows = useQuery({
    queryKey: ['GET_AI_FLOWS'],
    queryFn: getAiFlow,
    enabled: isDeveloper,
    staleTime: Infinity,
  });

  return {
    aiFlowList: aiFlows.data?.data.aiflows,
  };
};
