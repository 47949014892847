import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
} from '@mui/material';
import {
  FormContainer,
  useForm,
  TextFieldElement,
  AutocompleteElement,
} from 'react-hook-form-mui';
import { useModal } from '../hooks';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { feedbackService, libraryService } from '../api';

interface FormInputs {
  title: string;
  retrieval_bundle_slug: { id: string; label: string };
  url: string;
  authors: string;
  owner_organization: string;
  owner_contact: string;
  owner_email: string;
}

interface Props {
  label: 'Document' | 'Reference';
  onSubmitSuccess?: () => void;
}

export const ReportMissingDocument: React.FC<Props> = ({
  label,
  onSubmitSuccess,
}) => {
  const { openModal, closeModal, currentModal } =
    useModal<'addMissingDocument'>();

  const handleAdd = () => {
    openModal('addMissingDocument');
  };

  const handleClose = () => {
    closeModal('addMissingDocument');
    reset();
  };

  const formContext = useForm<FormInputs>({
    defaultValues: {
      title: '',
      retrieval_bundle_slug: {},
      url: '',
      authors: '',
      owner_organization: '',
      owner_contact: '',
      owner_email: '',
    },
  });

  const { handleSubmit, clearErrors, reset } = formContext;
  const { sendMissingDocumentFeedback, isPending } = feedbackService();
  const { retrievalBundlesTagsList = [] } = libraryService();
  const collectionList = retrievalBundlesTagsList.map(({ name, slug }) => ({
    id: slug,
    label: name,
  }));

  const handleSubmitFeedback = (data: FormInputs) => {
    sendMissingDocumentFeedback({
      ...data,
      retrieval_bundle_slug: data.retrieval_bundle_slug.id,
    }).then(() => {
      clearErrors();
      reset();
      handleClose();

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    });
  };

  return (
    <>
      <Button onClick={handleAdd} variant="outlined">
        Report missing {label}
      </Button>

      <Dialog
        onClose={handleClose}
        open={currentModal === 'addMissingDocument'}
        fullWidth
      >
        <DialogTitle>
          <Stack alignItems="center" direction="row" gap={1}>
            <UploadFileRoundedIcon fontSize="large" />
            <span>Missing {label}</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FormContainer
            formContext={formContext}
            handleSubmit={handleSubmit(handleSubmitFeedback)}
          >
            <FormControl fullWidth variant="outlined">
              <Stack gap={2}>
                Please report the missing {label.toLocaleLowerCase()}.
                <TextFieldElement
                  required
                  label="Document title"
                  name="title"
                />
                <AutocompleteElement
                  label="Collection"
                  name="retrieval_bundle_slug"
                  options={collectionList}
                  autocompleteProps={{
                    filterSelectedOptions: true,
                  }}
                />
                <TextFieldElement type="url" label="URL" name="url" />
                <TextFieldElement label="Author(s)" name="authors" />
                <TextFieldElement
                  label="Document owner organization"
                  name="owner_organization"
                />
                <TextFieldElement
                  label="Document owner contact person"
                  name="owner_contact"
                />
                <TextFieldElement
                  type="email"
                  label="Contact person's e-mail address"
                  name="owner_email"
                />
              </Stack>
            </FormControl>
          </FormContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={handleSubmit(handleSubmitFeedback)}
            autoFocus
            variant="contained"
            disabled={isPending}
          >
            {isPending ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
