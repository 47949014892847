import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SourceDocument } from '../types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { TitleSection } from './Common';
import { MuiMarkDown } from './MuiMarkdown';
import { getDocumentTitleFromSource, getDocumentUrl } from '../tools';
import { DocumentReferenceFeedback } from './DocumentReferenceFeedback';
import { ReportMissingDocument } from './ReportMissingDocument';

interface Props {
  sourceDocuments: SourceDocument[] | null;
  cuid: string;
  quid: string;
}

const handleDocumentOpen =
  (url: string, queryTemplate: string | null, page: number | null) => () => {
    window.open(getDocumentUrl(url, queryTemplate, page), '_blank')?.focus();
  };

export const References: React.FC<Props> = ({
  sourceDocuments,
  cuid,
  quid,
}) => {
  const showReferences = !!sourceDocuments?.length;

  return (
    <TitleSection
      title="References:"
      icon={<LibraryBooksIcon fontSize="medium" />}
      contentPadding="32px"
    >
      {showReferences && (
        <Accordion elevation={1}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {sourceDocuments.length} references available, expand for details.
          </AccordionSummary>
          <AccordionDetails>
            {sourceDocuments.map(
              (
                {
                  page,
                  page_content,
                  source,
                  uc_doc_title,
                  uc_doc_view_url_per_owner,
                  uc_doc_view_url_pg_target,
                  uc_doc_id,
                },
                i,
              ) => {
                return (
                  <Accordion
                    elevation={0}
                    key={i}
                    sx={{
                      '& p, & strong': {
                        //use sx and select related class
                        fontSize: '14px', // change its style
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Stack
                        alignItems="center"
                        flex={1}
                        direction="row"
                        spacing={1}
                      >
                        <PictureAsPdfIcon fontSize="small" color="action" />
                        <Typography>
                          {uc_doc_title
                            ? uc_doc_title
                            : getDocumentTitleFromSource(source)}
                        </Typography>
                        <Typography
                          noWrap
                          textOverflow="initial"
                          variant="overline"
                          pr={1}
                          pl={1}
                        >
                          page {page}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack gap={1}>
                        <MuiMarkDown mdContent={page_content} />
                        <DocumentReferenceFeedback
                          ucDocId={uc_doc_id}
                          quid={quid}
                          cuid={cuid}
                        />
                        <Stack gap={1} justifyContent="end" direction="row">
                          {!!uc_doc_view_url_per_owner && (
                            <Button
                              onClick={handleDocumentOpen(
                                uc_doc_view_url_per_owner,
                                uc_doc_view_url_pg_target,
                                page,
                              )}
                              variant="contained"
                            >
                              View document
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              },
            )}
            <Stack paddingTop={2} justifyContent="end" direction="row">
              <ReportMissingDocument label="Reference" />
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
      {!showReferences && (
        <Stack paddingTop={2} justifyContent="end" direction="row">
          <ReportMissingDocument label="Reference" />
        </Stack>
      )}
    </TitleSection>
  );
};
